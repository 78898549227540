// eslint-disable-next-line import/prefer-default-export
export function lineOptionsMulties(text, measures, tempDataType, series, colors, yAxis) {
  return {
    color: colors,
    title: {
      text: text || '',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: measures,
    },

    toolbox: {
      show: false,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: {
          show: true,
          title: 'mark',
        },
        magicType: {
          show: true,
          type: ['line', 'bar', 'stack', 'tiled'],
          title: 'Tip',
        },
        dataView: {
          show: true,
          readOnly: false,
          title: 'Veri',

        },
        restore: {
          show: true,
          title: 'Yenile',

        },
        saveAsImage: {
          show: true,
          title: 'Ekran Kayıt',
        },
      },
    },
    grid: {
      right: '6%',
      left: '6%',
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        alignWithLabel: true,
      },
      data: tempDataType,
    }],
    yAxis,
    series,
    dataZoom: [{
      show: true,
      height: 10,
      xAxisIndex: [0],
      bottom: 30,
      start: 0,
      end: 200,
      handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
      handleSize: '110%',
      handleStyle: {
        color: '#d3dee5',
      },
      textStyle: {
        color: '#fff',
      },
      borderColor: '#90979c',
    },
    {
      type: 'inside',
      show: true,
      height: 10,
      start: 1,
      end: 35,
    },
    ],
  };
}

export function lineOptionEmpty(title = '', measureList = [], xAxisData = []) {
  return {
    title: {
      text: title,
      left: 'left',
      textStyle: {
        fontSize: 14,
        align: 'center',
      },
      bottom: -8,
    },

    tooltip: {
      trigger: 'axis',
      axisPointer: { // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },

    },
    legend: {
      data: measureList,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',

    },

    series: [],
  };
}
