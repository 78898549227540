<template>
  <v-container fluid>
    <v-row dense>
      <v-col
        cols="12"
        class="my-3"
      >
        <v-card>
          <v-row class="mx-3">
            <v-spacer />

            <v-col
              cols="12"
              xl="2"
              lg="2"
              sm="4"
              md="3"
            >
              <v-select
                v-model="santral"
                :items="santralDataList"
                label="Santral"
                outlined
                dense
              />
            </v-col>

            <v-col
              cols="12"
              xl="2"
              lg="2"
              sm="4"
              md="3"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    v-model="startDate"
                    label="Tarih Seçici"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    İptal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startDate)"
                  >
                    Tamam
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              xl="2"
              lg="2"
              sm="4"
              md="3"
            >
              <v-select
                v-model="chartTypeModel"
                :items="chartType"
                label="Grafik"
                outlined
                dense
              />
            </v-col>

            <v-col
              cols="12"
              sm="2"
              md="2"
              xl="1"
              lg="1"
            >
              <v-btn
                color="primary"
                @click="getDailyMinuteEnergy"
              >
                Filtrele
              </v-btn>
            </v-col>
          </v-row>

          <v-col
            ref="renderComponent"
            cols="12"
          >
            <template v-if="livehourdata">
              <echart
                id="santral-sensor-vals"
                class-name="widget-content"
                height="400px"
                width="100%"
                :chart-data="livehourdata"
              />
            </template>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      santral: null,
      santralDataList: [], // Add your data list here
      startDate: null,
      menu: false,
      chartTypeModel: null,
      chartType: [], // Add your chart types here
      livehourdata: null, // Add your chart data here
    };
  },
  methods: {
    getDailyMinuteEnergy() {
      // Your method to fetch data
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .pr-3 {
    padding-right: 0 !important;
  }
}
</style>

<script>
import Echart from '@/views/components/Echart/Chart.vue';
import { getSantralSensorChartOpts } from '@/api/SensorBox/ChartOption';
import moment from 'moment';
import i18n from '@/locale';
import helper from '@/api/helper';
import { list } from '@/api/Request/sensor';

export default {
  name: 'SensorBoXDailyChart',
  components: {
    Echart,
  },
  data: () => ({
    santral: '',
    componentKey: 1,
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),
    menu: false,
    modal: false,
    menu2: false,
    alarmDialog: false,
    livehourdata: {},
    inverterList: {},
    sensorInfo: {},
    chartType: ['line', 'bar', 'line2'],
    chartTypeModel: 'line2',
    sensorMeasureList: {},
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    santralDataList() {
      const { santralDataList } = this.$store.getters;

      if (Object.keys(santralDataList).length > 0) {
        return santralDataList;
      }
      return [];
    },
  },
  watch: {
    santral() {
      this.getDailyMinuteEnergy();
    },
  },
  created() {},
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 2000);

    const self = this;
    let i = 0;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const { sensorData, sensorMeasureList } = self.$store.getters;
      if (i > 30) {
        clearInterval(myVar1);
      }
      i += 1;

      if (santral !== undefined && Object.keys(sensorData).length > 0) {
        self.santral = santral;
        self.sensorInfo = sensorData;
        self.sensorMeasures = sensorMeasureList;
        clearInterval(myVar1);
      }
    }
  },
  methods: {
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');
      const text = '';
      const sensorList = [];
      this.sensorInfo.forEach((item) => {
        if (sensorList.indexOf(item.SENSOR) === -1) {
          sensorList.push(item.SENSOR);
        }
      });

      const { sensorMeasures } = this.$store.getters;
      const measures = [];
      Object.keys(sensorMeasures).forEach((k) => {
        measures.push(sensorMeasures[k].value);
      });

      const sensorMeasure = [];
      const temps = [];
      const tempmeasureList = {};

      Object.keys(this.sensorInfo).forEach((i) => {
        const measure = this.sensorInfo[i].general_measure_list;
        // eslint-disable-next-line no-underscore-dangle
        const _m = [];
        Object.keys(this.sensorInfo[i].general_measure_list).forEach((t) => {
          if (measures.indexOf(measure[t].value) > -1) {
            const obj = {
              value: measure[t].value,
              text: i18n.t(measure[t].text),
              type: measure[t].type,
              unit: measure[t].unit,
            };
            _m.push(obj);

            if (temps.indexOf(measure[t].value) === -1) {
              sensorMeasure.push(obj);
              temps.push(measure[t].value);
            }
          }
        });

        tempmeasureList[this.sensorInfo[i].SENSOR] = _m;
      });

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          sensor: sensorList,
          measures,
        },
      };

      const stepTime = helper.getSantralStep(this.santral);
      list(params).then((_rawData) => {
        this.livehourdata = getSantralSensorChartOpts(
          this.sensorInfo,
          _rawData,
          stepTime,
          tempmeasureList,
          text,
          this.chartTypeModel,
        );
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
