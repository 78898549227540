import i18n from '@/locale';
import helper from '@/api/helper';
import {
  lineOptionEmpty,
} from '@/api/chart/options';
import store from '@/store';
 
export function setMinuteLable(item) {
  let hh;
  let mm;

  if ('Hour' in item) {
    // eslint-disable-next-line no-unused-expressions
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
  }
  if ('MinuteGrup' in item) {
    // eslint-disable-next-line no-unused-expressions
    item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
  }

  return `${hh}:${mm}`;
}

// eslint-disable-next-line no-underscore-dangle
export function _xAxis(measure = {}, offset = {}, position = {}, color = null, min = null, max = null) {
  // eslint-disable-next-line no-shadow
  const xAxis = {
    type: 'value',
    name: `${i18n.t(measure.value)}(${measure.unit})`,
    offset,
    position,
    axisLine: {
      show: true,
    },
    axisLabel: {
      formatter: `{value} ${measure.unit}`,
    },
  };
  if (min != null) {
    xAxis.min = min;
  }
  if (max != null) {
    xAxis.max = max;
  }

  if (color != null) {
    xAxis.axisLine.lineStyle = {
      color,
    };
  }

  return xAxis;
}

export function setMinuteData(hour, minute, timeData, measure, stepTime = 5) {
  const tempData = [];

  for (let i = hour; i <= 20; i += 1) {
    for (let x = minute; x < 60; x += stepTime) {
      let h;
      let m;
      // eslint-disable-next-line no-unused-expressions
      i < 10 ? h = `0${i}` : h = `${i}`;
      // eslint-disable-next-line no-unused-expressions
      x < 10 ? m = `0${x}` : m = `${x}`;

      const time = `${h}:${m}`;

      if (time in timeData) {
        tempData.push(Math.round(timeData[time][measure.value], 1));
      } else {
        tempData.push(0);
      }
    }
  }
  return tempData;
}

export function getMinuteTimeField(hour, minute, stepTime) {
  const timeField = [];
  for (let i = hour; i <= 20; i += 1) {
    for (let x = minute; x < 60; x += stepTime) {
      let h;
      let m;
      // eslint-disable-next-line no-unused-expressions
      i < 10 ? h = `0${i}` : h = `${i}`;
      // eslint-disable-next-line no-unused-expressions
      x < 10 ? m = `0${x}` : m = `${x}`;

      const time = `${h}:${m}`;

      timeField.push(time);
    }
  }
  return timeField;
}

export function dataSort(_rawData, sortType) {
  return _rawData.sort((a, b) => {
    if (a[sortType] < b[sortType]) return -1;
    if (a[sortType] > b[sortType]) return 1;
    return 0;
  });
}

export function emptyChart(text, measureList) {
  const measures = [];

  measureList.forEach((measure) => {
    measures.push(`${measure.value} ${measure.unit}`);
  });

  const {
    selectedFarm,
  } = store.getters;

  const stepTime = helper.getFarmStep(selectedFarm.prefix);
  const minute = 0;
  const hour = 5;
  const timeFields = [];
  for (let i = hour; i <= 20; i += 1) {
    for (let x = minute; x < 60; x += stepTime) {
      let h;
      let
        m;
      // eslint-disable-next-line no-unused-expressions
      i < 10 ? h = `0${i}` : h = `${i}`;
      // eslint-disable-next-line no-unused-expressions
      x < 10 ? m = `0${x}` : m = `${x}`;

      const time = `${h}:${m}`;

      timeFields.push(time);
    }
  }
  return lineOptionEmpty(text, measures, timeFields);
}
